export const fetchConfig = {
  endpoint: process.env.API_URL,
  params: {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.CONTENTFUL_ACCESS_TOKEN}`,
      'Content-Language': 'en-us'
    }
  },
  jobsEndpoint: process.env.JOBS_API_URL,
  jobParams: {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer`,
      'Content-Language': 'en'
    }
  }
};

// authorization: `Bearer ${CONTENTFUL_ACCESS_TOKEN}`,
