import ContentfulImage from '@/components/contentful-image';
import Link from 'next/link';

const LinkCtf = ({
  link,
  target,
  hasMargin,
  noTitle,
  pdfLink,
  width,
  height,
  noUnderline
}: any) => {
  return (
    <Link
      target={target ? '_blank' : '_self'}
      href={pdfLink ? pdfLink : link?.urlPath}
    >
      <div className={`flex items-center gap-2 ${noUnderline ? '' : 'single-underline-animation'}`}>
        {!noTitle && (
          <div className={hasMargin ? 'mt-[4px]' : ''}>{link?.title}</div>
        )}
        {link?.document?.url && !pdfLink && (
          <ContentfulImage
            src={link?.document?.url}
            alt={link?.document?.title}
            width={width}
            height={height}
          />
        )}
      </div>
    </Link>
  );
};

export default LinkCtf;
