import Image from 'next/image';

interface ContentfulLoaderIC {
  src: string;
  width: number;
  quality?: number;
}

const contentfulLoader = ({ src, width, quality }: ContentfulLoaderIC) => {
  return `${src}?w=${width}&q=${quality || 75}&fm=webp`;
};

const contentfulSvgLoader = ({ src, width, quality }: ContentfulLoaderIC) => {
  return `${src}?w=${width}&q=${quality || 75}`;
};

const ContentfulImage = (props: any) => {
  const { src } = props;
  const isString = typeof src === 'string';
  const isSvg = isString ? src.endsWith('.svg') : src.src.endsWith('.svg');

  return (
    <Image loader={isSvg ? contentfulSvgLoader : contentfulLoader} {...props} />
  );
};

export default ContentfulImage;
