import ContentfulImage from '@/components/contentful-image';
import { LanguageSelector } from '@/components/language-selector';
import { MenuGroupIC } from '@/lib/interface-types';
import ArrowLink from '@/public/icons/Arrow-link.svg';
import CloseGreen from '@/public/icons/close-green.svg';
import HeartIcon from '@/public/icons/heart-footer.svg';
import instagram from '@/public/icons/instagram-white-bg.svg';
import linkedln from '@/public/icons/linkedin-white-bg.svg';
import GreenMinus from '@/public/icons/remove.svg';
import YoutubeIcon from '@/public/icons/youtube-video.svg';
import PlusIcon from '@/public/plus-icon.svg';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import uuid from 'react-uuid';
import MobileNavigationItem from './mobile-navigation-item';

const MobileMenu = ({ items, closeMenuHandler }: any) => {
  const { t } = useTranslation();
  const { locale } = useRouter();
  const [itemsWithSubmenu, setItemsWithSubmenu] = useState<MenuGroupIC[]>([]);
  const [menuItems, setMenuItems] = useState<MenuGroupIC[]>([]);
  const [openTab, setOpenTab] = useState('');

  useEffect(() => {
    const submenuItems = [...items.filter((i: MenuGroupIC) => !i.groupLink)];
    const menuElements = [
      ...items.filter(
        (i: MenuGroupIC) => !i.internalTitle.toLowerCase().includes('contact')
      )
    ];
    setItemsWithSubmenu(submenuItems);
    setMenuItems(menuElements);
  }, [items]);

  const handleOpenTab = (id: string) => {
    setOpenTab(id === openTab ? '' : id);
  };

  const currentYear = new Date().getFullYear();

  return (
    <div className="MobileMenu overflow-scroll bg-brand-deep-blue fixed top-0 right-0 left-0 bottom-0 p-6 z-50 flex flex-col justify-between">
      <div className="flex flex-row justify-between justify-items-center">
        <LanguageSelector mobile />
        <div onClick={() => closeMenuHandler()} aria-hidden="true">
          <Image
            src={CloseGreen}
            alt="Close menu icon"
            width={16}
            height={16}
            className=""
          />
        </div>
      </div>
      <div className="flex flex-col grow mt-12">
        <ul className={`flex flex-col list-none m-0`}>
          <li className="py-4">
            <Link
              href="/"
              className="uppercase font-bold text-[1.063rem] text-white flex justify-between"
            >
              {t('mobile_menu.home')}
              <Image src={ArrowLink} alt="arrow link" width={25} height={25} />
            </Link>
          </li>
          {menuItems &&
            menuItems.map((groupItem: MenuGroupIC) => (
              <li key={uuid()} className="py-4">
                {!groupItem?.groupLink ? (
                  <>
                    <button
                      type="button"
                      onClick={() => handleOpenTab(groupItem.sys.id)}
                      className={`uppercase font-bold text-[1.063rem] flex justify-between w-full ${
                        openTab === groupItem.sys.id
                          ? 'text-brand-green '
                          : 'text-white'
                      }`}
                    >
                      {groupItem.groupName}
                      <div>
                        <Image
                          src={
                            openTab === groupItem.sys.id ? GreenMinus : PlusIcon
                          }
                          alt={openTab === groupItem.sys.id ? 'open' : 'closed'}
                          width={25}
                          height={25}
                        />
                      </div>
                    </button>
                    <div className={`${!openTab ? 'hidden' : ''}`}>
                      {itemsWithSubmenu &&
                        itemsWithSubmenu
                          .filter((e) => e.sys.id === groupItem.sys.id)
                          .map((groupSubItem: MenuGroupIC) => (
                            <div
                              key={uuid()}
                              className={
                                openTab === groupSubItem.sys.id
                                  ? 'block'
                                  : 'hidden'
                              }
                            >
                              <MobileNavigationItem groupItem={groupSubItem} />
                            </div>
                          ))}
                    </div>
                  </>
                ) : (
                  <Link
                    href={`/${groupItem.groupLink?.urlPath}`}
                    className="uppercase font-bold text-[1.063rem] text-white flex justify-between"
                  >
                    {groupItem.groupName}
                    <Image
                      src={ArrowLink}
                      alt="arrow link"
                      width={25}
                      height={25}
                    />
                  </Link>
                )}
              </li>
            ))}
        </ul>
        <div className="py-10">
          <Link className="green-button px-6 py-3" href="/contact">
            {t('mobile_menu.contact')}
          </Link>
        </div>

        <div className={`mt-12  `}>
          <ContentfulImage src={HeartIcon} width={40} height={40} />
          <h2 className="text-white mt-4">
            Human. Technology.{' '}
            <span className="text-brand-green">Together.</span>
          </h2>
        </div>
        <div className={`mt-6`}>
          <p className="text-white">Follow us on social media:</p>
          <div className="flex mt-3">
            <Link
              href={`https://www.instagram.com/consulteer/?hl=${
                locale === 'en-US' ? 'en' : 'de'
              }`}
              target="_blank"
            >
              <Image src={instagram} alt="Instagram" width={40} height={40} />
            </Link>
            <Link
              href="https://www.linkedin.com/company/consulteer/posts/?feedView=all"
              className="ml-6"
              target="_blank"
            >
              <Image src={linkedln} alt="Linkedln" width={40} height={40} />
            </Link>
            <Link
              href="https://www.youtube.com/@Consulteer"
              className="ml-6"
              target="_blank"
            >
              <Image src={YoutubeIcon} alt="Linkedln" width={50} height={40} />
            </Link>
          </div>
        </div>
      </div>
      <div className="flex flex-row mt-8 mb-5 justify-between items-end">
        <div>
          <div className={`text-shades-white `}>© {currentYear} Consulteer</div>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
