import { SubmenuListIC } from '@/lib/interface-types';
import Link from 'next/link';
import uuid from 'react-uuid';

const MobileSubmenu = ({
  title,
  items,
  href,
}: SubmenuListIC) => {
  if (!items?.length) return null;
  return (
    <div className={`MobileSubmenu ${title && 'mb-8'}`}>
      {title && (
        <Link
          href={href || ''}
          className="uppercase underline font-semibold text-white"
        >
          {title}
        </Link>
      )}
      {items?.length && (
        <div className={`flex flex-col ${title && 'mt-4'}`}>
          {items.map((item) => (
            <div className="text-white mb-4" key={uuid()}>
              {item.urlPath && (
                <Link
                  href={item.urlPath}
                  legacyBehavior
                  className="text-[1.063rem] leading-9 text-white my-2"
                >
                  {item.title}
                </Link>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
export default MobileSubmenu;
