import ArrowUp from '@/public/icons/Dropdown.svg';
import ArrowDown from '@/public/icons/Arrowdown.svg';
import ArrowDownWhite from '@/public/icons/ArrowdownWhite.svg';
import { useRouter } from 'next/router';
import { useState } from 'react';

interface PropsIC {
  mobile: boolean;
}

export const LanguageSelector = ({ mobile }: PropsIC) => {
  const { locale, locales } = useRouter();
  const router = useRouter();
  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);
  const upIcon = mobile ? ArrowDownWhite : ArrowUp;
  const downIcon = mobile ? ArrowDownWhite : ArrowDown;

  const handleButtonClick = () => {
    setIsSubMenuOpen(!isSubMenuOpen);
  };

  const handleLocaleChange = (selectedLocale: string) => {
    router.push(
      { pathname: router.pathname, query: router.query },
      router.asPath,
      {
        locale: String(selectedLocale)
      }
    );
  };

  return locales && locales.length > 1 ? (
    <div className={`relative flex mt-[8px] ${!mobile && 'ml-6'}`}>
      {locales.map((availableLocale) => (
        <button
          key={availableLocale}
          onClick={() => handleLocaleChange(availableLocale)}
          className={`cursor-pointer text-[1.063rem] font-bold uppercase inline-flex items-center ${
            mobile ? 'mr-2' : 'mx-1 cursor-pointer'
          }`}
        >
          <div
            className={`cursor-pointer ${
              locale === availableLocale ? 'text-brand-green ' : 'text-white min-[1151px]:text-black'
            } font-bold uppercase inline-flex items-center single-underline-animation`}
          >
            {availableLocale === 'en-US' ? 'EN' : 'DE'}
          </div>
        </button>
      ))}
    </div>
  ) : null;
};
