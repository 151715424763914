import { ContainerForCategoryLink } from '@/lib/interface-types';
import Link from 'next/link';
import { useRouter } from 'next/router';

export interface HeaderCardIC {
  groupItem: {
    containerForCategoryLink: ContainerForCategoryLink;
  };
  className?: string;
}

const HeaderCard = (props: HeaderCardIC) => {
  if (!props?.groupItem?.containerForCategoryLink) {
    return null;
  }

  const router = useRouter();
  const {
    groupItem: {
      containerForCategoryLink: { title, linkUrl }
    }
  } = props;

  const modifiedPath = router.asPath.replace(/\//g, '');

  return (
    <div className="HeaderCard">
      <Link href={`/${linkUrl}`}>
        <div>
          <h3
            className={`${props.className} single-underline-animation ${
              modifiedPath == linkUrl ? 'text-brand-green' : ''
            }`}
          >
            {title}
          </h3>
        </div>
      </Link>
    </div>
  );
};

export default HeaderCard;
