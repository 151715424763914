import { SubmenuListIC } from '@/lib/interface-types';
import Link from 'next/link';
import { useRouter } from 'next/router';
import uuid from 'react-uuid';

const SubmenuList = ({ slug, groupName, title, items, isSubMenuOpen }: SubmenuListIC) => {
  const urlLink = `/${groupName?.toLowerCase()}/${slug}`;
  if (!items?.length) return null;
  const router = useRouter();

  return (
    <div>
      {title && (
        <Link href={urlLink} className="single-underline-animation ">
          <h3
            className={`capitalize tansition-all duration-500 delay-700 opacity-0 ${
              urlLink === router.asPath ? 'text-brand-green' : ''
            }
                ${isSubMenuOpen && 'opacity-100'} ${
              isSubMenuOpen ? 'translate-y-0' : '-translate-y-[-25%]'
            }
            `}
          >
            {title}
          </h3>
        </Link>
      )}
      <div
        className={`text-black uppercase tansition-all duration-500 delay-700 opacity-0 ${
          title ? 'mt-6' : ''
        } ${isSubMenuOpen && 'opacity-100'} ${
          isSubMenuOpen ? 'translate-y-0' : '-translate-y-[-25%]'
        }`}
      >
        {items.map((item) => (
          <div className="mt-4" key={uuid()}>
            {item.urlPath ? (
              <Link
                href={item.urlPath}
                className={`text-[1.0625rem] leading-6 single-underline-animation ${
                  item.urlPath === router.asPath ? 'text-brand-green' : ''
                }`}
              >
                {item.title}
              </Link>
            ) : (
              <span className="text-[1.0625rem] leading-6">{item.title}</span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SubmenuList;
